import { HomePageInterface } from './HomePage.interface'
import { Debug } from '../../organisms'
import { InfoBlock } from '../../molecules'

const HomePage = ({ testID, data }: HomePageInterface) => {
    const list = data.find((item: any) => item.type === 'homepageConcept')

    return (
        <div data-testid={testID} className={`HomePage`}>
            {list.pageContent.map((item: any, index: number) => {
                return <InfoBlock type={index % 2 === 0 ? 'left' : 'right'} content={item} />
            })}
        </div>
    )
}

export default HomePage
