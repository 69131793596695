import { useState, useEffect } from 'react'
import { MenuInterface } from './Menu.interface'
import { Link } from 'react-router-dom'
import LogoBlack from '../../../resources/images/thiago-logo-dark.svg'
import Hamburger from 'hamburger-react'
import { PriceTag } from '../../molecules'

const Menu = ({ testID, data, active }: MenuInterface) => {
    const [isVisible, setIsVisible] = useState(false)
    const [isOpen, setOpen] = useState(false)
    // const slug = active?.slug || '/'

    const items = [
        { title: 'Home', slug: '/' },
        { title: 'Producten' },
        { title: 'Contact', slug: '/contact' }
        //   { title: 'Over', slug: '/over-ons' }
    ]

    const [color, setColor] = useState('normal')

    useEffect(() => {
        const listenScrollEvent = () => {
            if (window.scrollY > 100) {
                setColor('scroll')
            } else {
                setColor('normal')
            }
        }

        window.addEventListener('scroll', listenScrollEvent)
    }, [])

    const renderDesktop = () => {
        return <div className={'menu-desktop'}>{renderTheWholeMenu()}</div>
    }

    const renderMobile = () => {
        return (
            <div className={'menu-mobile'}>
                <Hamburger
                    color={`${isOpen || color === 'scroll' ? '#827A65' : '#FFF'}`}
                    toggled={isOpen}
                    toggle={() => {
                        setOpen(!isOpen)
                        setIsVisible(true)
                    }}
                />
            </div>
        )
    }

    const renderTheWholeMenu = () => {
        return (
            <ul style={{ height: 36, marginTop: -0 }}>
                {items?.map((item: any, index: number) => {
                    if (item.slug) {
                        return (
                            <li key={`mnu-${index}`} data-active={item.slug === active?.slug}>
                                <Link to={item.slug} onClick={() => setOpen(false)}>
                                    {item.title}
                                </Link>
                            </li>
                        )
                    }

                    if (item.title === 'Producten') {
                        return (
                            <>
                                <li key={'mnx-zero'}>
                                    <div onClick={() => setIsVisible(!isVisible)}>
                                        {item.title} &nbsp;
                                        <i className={`bi bi-arrow-${isVisible ? 'left' : 'right'}`}></i>
                                    </div>
                                </li>

                                {isVisible &&
                                    data?.map((item: any, index: number) => {
                                        if (item.type === 'product') {
                                            return (
                                                <li
                                                    key={`product-${index}`}
                                                    data-active={item.slug === active?.slug}
                                                    data-object-type={'product-link'}
                                                >
                                                    <Link to={item.slug} onClick={() => setOpen(false)}>
                                                        {item.title}
                                                    </Link>
                                                </li>
                                            )
                                        }
                                    })}
                            </>
                        )
                    }
                })}
            </ul>
        )
    }

    const renderMobileMenu = () => {
        if (isOpen) {
            return (
                <div className={'overlay-menu'}>
                    <div className={'mobile-logo'}>
                        <img src={LogoBlack} alt={'Guilhermo'} />
                    </div>
                    <div className={'menu-container'}>{renderTheWholeMenu()}</div>
                </div>
            )
        }
    }

    return (
        <div data-testid={testID} data-theme={color} className={`Menu`}>
            <div className={'menu-logo'}>
                <img src={LogoBlack} alt={'Guilhermo'} />
            </div>
            {renderDesktop()}
            {renderMobile()}
            {renderMobileMenu()}
        </div>
    )
}

export default Menu
