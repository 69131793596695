// import { useState, useEffect, useContext } from 'react'
import { InputFieldInterface } from './InputField.interface'

const InputField = ({ type, name, required, placeholder, value, onChange, testID }: InputFieldInterface) => {
    const changeHandler = (v: string) => {
        onChange(v)
    }

    return (
        <input
            data-testid={testID}
            className='InputField'
            type={type}
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={(e) => changeHandler(e.target.value)}
            required={required}
        />
    )
}

export default InputField
