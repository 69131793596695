// import { useState, useEffect, useContext } from 'react'
import { Heading } from '../../atoms'
import { ThankYouInterface } from './ThankYou.interface'

const ThankYou = ({ testID }: ThankYouInterface) => {
    return (
        <div data-testid={testID} className={`ThankYou`}>
            <Heading type={'h2'} text={'Dank u voor uw interesse in Guilhermo'} />
        </div>
    )
}

export default ThankYou
