// import { useState, useEffect, useContext } from 'react'
import { MakeAppointmentInterface } from './MakeAppointment.interface'
import { Link } from 'react-router-dom'

const MakeAppointment = ({ testID }: MakeAppointmentInterface) => {
    return (
        <Link to='/afspraak' data-testid={testID} className={`MakeAppointment`}>
            Maak een afspraak
        </Link>
    )
}

export default MakeAppointment
