import { HeroInterface } from './Hero.interface'
import { Button, Heading, Logo, Paragraph } from '../../atoms'
import { ProductBlock } from '../../molecules'
import { config } from '../../../config'
import { Container, Row } from 'react-bootstrap'
import Background from '../../../resources/images/home-page.jpeg'

const Hero = ({ testID, type, img, page, heroSlogan = null, heroText = null, link = false }: HeroInterface) => {
    const bg = img ? img : Background

    const renderSlogan = () => {
        if (heroSlogan) {
            return (
                <div className='hero-slogan'>
                    <Heading type={'h2'} text={heroSlogan} color={'#FFF'} />
                </div>
            )
        }
    }

    const renderText = () => {
        if (heroText) {
            return (
                <div className='hero-description'>
                    <Paragraph type={'markdown'} content={heroText} />
                </div>
            )
        }
    }

    const renderPageContent = () => {
        return (
            <div className='hero-text'>
                <div>
                    {renderSlogan()}
                    {renderText()}
                </div>
                {link && <Button text={'Lees meer'} action={''} />}
            </div>
        )
    }

    return (
        <Container
            fluid
            data-testid={testID}
            data-object-type={type ?? ''}
            className={`Hero`}
            style={{ backgroundImage: `url(${bg})` }}
        >
            <div className='overlay'>
                <Container>
                    <div className='hero-content'>
                        <Logo />
                        {renderPageContent()}
                    </div>
                </Container>
                <Container>
                    <Row>
                        {config.blocks.map((block, index) => (
                            <ProductBlock key={index} {...block} />
                        ))}
                    </Row>
                </Container>
            </div>
        </Container>
    )
}

export default Hero
