import { ParagraphInterface } from './Paragraph.interface'

import MarkdownView from 'react-showdown'

const Paragraph = ({ testID, type, content, format = false }: ParagraphInterface) => {
    const renderMarkdown = () => {
        if (type === 'markdown') {
            const c = format ? content.replace(/\r\n|\r|\n/g, '') : content
            return (
                <MarkdownView
                    markdown={c}
                    options={{ tables: true, emoji: true, simpleLineBreaks: true, strikethrough: true }}
                />
            )
        }
    }

    const renderPlainText = () => {
        if (type === 'text') {
            return <p>{content}</p>
        }
    }

    const renderHtmlText = () => {
        if (type === 'html') {
            return <div dangerouslySetInnerHTML={{ __html: content }} />
        }
    }

    return (
        <div data-testid={testID} data-object-type={type ?? ''} className={`Paragraph`}>
            {renderMarkdown()}
            {renderPlainText()}
            {renderHtmlText()}
        </div>
    )
}

export default Paragraph
