// import { useState, useEffect, useContext } from 'react'
import { MobileBarInterface } from './MobileBar.interface'
import { Col, Container, Row } from 'react-bootstrap'
import { config } from '../../../config'
import { Link } from 'react-router-dom'

const MobileBar = ({ testID, style, type, size, props }: MobileBarInterface) => {
    return (
        <Container fluid>
            <Row data-testid={testID} data-object-type={type ?? ''} className={`MobileBar`}>
                {config?.blocks.map((item: any, index: number) => {
                    return (
                        <Col key={index} md={4} sm={4} xs={4} className={`MobileBar__col mcol-${index}`}>
                            <Link to={item.link}>
                                <img src={item.img} alt={item.title} />
                                {item.title}
                            </Link>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}

export default MobileBar
