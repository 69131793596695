// import { useState, useEffect, useContext } from 'react'
import { ProductListInterface } from './ProductList.interface'
import { Container, Row, Col } from 'react-bootstrap'
import Debug from '../Debug'

const ProductList = ({ testID, type, data }: ProductListInterface) => {
    return (
        <Container fluid data-testid={testID} className={`ProductList`}>
            <Row>
                {data &&
                    data.map((item, index) => {
                        return (
                            <Col
                                md={4}
                                sm={6}
                                xs={6}
                                key={index}
                                data-object-type={type ?? ''}
                                className='ProductList__item'
                            >
                                {item?.img && <img src={item.img} alt={item.title} />}
                                {item?.title && <p>{item.title}</p>}
                            </Col>
                        )
                    })}
            </Row>
        </Container>
    )
}

export default ProductList
