import { InformationPageInterface } from './InformationPage.interface'
import { ContactForm, Debug } from '../../organisms'
import { Row, Col, Container } from 'react-bootstrap'
import { Heading, Paragraph } from '../../atoms'
import { InfoBlock } from '../../molecules'

const InformationPage = ({ testID, type, data }: InformationPageInterface) => {
    const pg = data.find((pg: any) => pg.slug === '/' + type)

    const renderAboutUs = () => {
        if (pg.slug === '/over-ons') {
            return (
                <>
                    {pg?.pageContent &&
                        pg.pageContent.map((item: any, index: number) => {
                            return <InfoBlock type={index % 2 === 0 ? 'left' : 'right'} content={item} />
                        })}
                </>
            )
        }
    }

    const renderAppointment = () => {
        if (pg.slug === '/afspraak') {
            return (
                <Container>
                    <Row>
                        <Col md={3} />
                        <Col md={6} className={'page-content'}>
                            <div className={'contact-info'}>
                                <ContactForm extended={true} title={pg.title} />
                            </div>
                        </Col>
                        <Col md={3} />
                    </Row>
                </Container>
            )
        }
    }

    const renderContact = () => {
        if (pg.slug === '/contact') {
            return (
                <Container>
                    <Row>
                        <Col md={2} />
                        <Col md={8} className={'page-content'}>
                            <div className={'contact-info'}>
                                <Row>
                                    <Col md={6}>
                                        <Paragraph type={'markdown'} content={pg.pageContent[0].paragraph} />
                                    </Col>
                                    <Col md={6}>
                                        <ContactForm title={'Neem contact met ons op'} />
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col md={2} />
                    </Row>
                </Container>
            )
        }
    }

    const renderContent = () => {
        if (pg.slug !== '/contact' && pg.slug !== '/over-ons' && pg.slug !== '/afspraak') {
            return (
                <Container>
                    <Row>
                        <Col md={2} />
                        <Col md={8} className={'page-content'}>
                            <Heading type={'h1'} text={pg.title} color={'#000'} />
                            {pg.pageContent.map((item: any, index: number) => {
                                return (
                                    <div key={index} className={'article'}>
                                        <Heading type={'h3'} text={item.title} color={'#000'} />
                                        <Paragraph type={'markdown'} content={item.paragraph} />
                                    </div>
                                )
                            })}
                        </Col>
                        <Col md={2} />
                    </Row>
                </Container>
            )
        }
    }

    return (
        <div data-testid={testID} className={`InformationPage`}>
            {renderAboutUs()}
            {renderContent()}
            {renderContact()}
            {renderAppointment()}
        </div>
    )
}

export default InformationPage
