import SneakersCutOut from '../resources/images/sneakers-cutout.png'
import ShoesCutOut from '../resources/images/shoes-cutout.png'
import ModelsCutOut from '../resources/images/models-cutout.png'

import TileModel from '../resources/images/shoe.png'
import TileMaterial from '../resources/images/materialen.png'
import TileBelt from '../resources/images/riem.png'
import TileCustom from '../resources/images/customs.png'

export type itemType = {
    id: number
    title: string
    link: string
    img: any
}

const tiles = [
    { id: 1, title: 'Modellen', link: '/products/modellen', img: TileModel, type: 'models' },
    {
        id: 2,
        title: 'Materialen en kleuren',
        link: '/products/materialen-en-kleuren',
        type: 'materials',
        img: TileMaterial
    },
    {
        id: 3,
        title: 'Bijpassende riemen',
        link: '/products/bijpassende-riem',
        type: 'belts',
        img: TileBelt
    },
    {
        id: 4,
        title: 'Thiago Customs',
        link: '/products/customs',
        type: 'customs',
        img: TileCustom
    }
]

export const config = {
    slogan: 'Exclusieve handgemaakte schoenen, sneakers en riemen uit Portugal voor de zakelijke markt',
    heroText:
        '' +
        'Jij als zakeman/ondernemer kunt bij ons herenschoenen, sneakers en riemen voor jouw klanten bestellen die je zelf kunt samenstellen. Daarmee kun je jouw eigen exclusieve touch en uitstraling geven d.m.v. een zéér uitgebreid assortiment materialen, kleuren, zolen, veters, stiksels enz. Tevens kun je nog een bijpassende riem bestellen of zelfs jouw eigen unieke riemen als losse riemen samenstellen..\n',
    tiles,
    address: {
        street: 'Tongerenstraat 7',
        city: 'Stein',
        postalCode: '6171 AX',
        email: 'info@thiagoshoes.nl',
        phone: '+31629085185'
    },
    blocks: [
        {
            description: 'Bekijk onze nieuwe collectie sneakers',
            img: SneakersCutOut,
            link: '/products/sneakers',
            title: 'Sneakers'
        },
        {
            description: 'Dit zijn de modellen waar u uit kunt kiezen',
            img: ModelsCutOut,
            link: '/products/modellen',
            title: 'Herenschoenen'
        },
        {
            description: 'Eindeloze mogelijk-heden om zelf samen te stellen.',
            img: ShoesCutOut,
            link: '/products/customs',
            title: 'Customs'
        }
    ]
}
