import { useState } from 'react'
import { ContactFormInterface } from './ContactForm.interface'
import { Button, Heading, InputField, Spinner, TextArea, ThankYou } from '../../atoms'
import { Row, Col } from 'react-bootstrap'
import { saveContact } from '../../../lib'

const ContactForm = ({ testID, title, extended = false }: ContactFormInterface) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [msg, setMsg] = useState('')
    const [showThanks, setShowThanks] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [datum, setDatum] = useState('')
    const [time, setTime] = useState('')
    const [where, setWhere] = useState('')

    const date = new Date(Date.now())

    const times = ['13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00']

    const sendMessage = async () => {
        setShowSpinner(true)
        const site = 'guilhermo'
        await saveContact({
            site,
            date,
            name,
            email,
            phone,
            datum,
            time,
            where,
            msg
        })

        fetch('https://formsubmit.co/ajax/app.thiago.guilhermo@gmail.com', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                name: name,
                email: email,
                phone: phone,
                message: msg,
                datum: datum,
                time: time,
                where: where
            })
        })
            .then((response) => response.json())
            .then((data) => setShowThanks(true))
            .catch((error) => console.log(error))
            .finally(() => setShowThanks(true))
    }

    const renderExtended = () => {
        return (
            <Row>
                <Col md={6}>
                    <InputField
                        type={'date'}
                        name={'datum'}
                        required={true}
                        placeholder={''}
                        value={datum}
                        onChange={setDatum}
                    />
                </Col>
                <Col md={6}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <select
                            name={'time'}
                            className='form-control'
                            required={true}
                            style={{
                                width: '100%',
                                padding: 10,
                                marginTop: 20,
                                marginBottom: 20
                            }}
                            onChange={(e) => setTime(e.target.value)}
                        >
                            <option value=''>Kies een tijd</option>
                            {times.map((item, index) => {
                                return (
                                    <option key={index} value={item}>
                                        {item}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                </Col>
                <Col md={12}>
                    <select name='c_appointment' onChange={(e) => setWhere(e.target.value)} className='form-control'>
                        <option value=''>Waar wilt u de afspraak laten plaatsvinden?</option>
                        <option value='winkel'>In winkel</option>
                        <option value='thuis'>Thuis</option>
                        <option value='kantoor-werk'>Op kantoor/werk</option>
                    </select>
                </Col>
            </Row>
        )
    }

    const renderContent = () => {
        if (showThanks)
            return (
                <Col md={12}>
                    <ThankYou />
                </Col>
            )

        if (showSpinner) {
            return (
                <Col md={12}>
                    <Spinner />
                </Col>
            )
        }

        return (
            <Col md={12}>
                <Heading type={'h2'} text={title} />
                {extended && (
                    <p style={{ marginTop: 20, marginBottom: 20 }}>
                        Bent u enthousiast geraakt over onze producten? Vul onderstaand formulier in en we maken met u
                        een vrijblijvende afspraak. Wij reageren altijd binnen 24u.
                    </p>
                )}

                <InputField
                    type={'text'}
                    name={'naam'}
                    required={true}
                    placeholder={'Naam *'}
                    value={name}
                    onChange={setName}
                />
                <InputField
                    type={'text'}
                    name={'email'}
                    required={true}
                    placeholder={'Email adres *'}
                    value={email}
                    onChange={setEmail}
                />
                <InputField
                    type={'text'}
                    name={'telefoonnr'}
                    required={false}
                    placeholder={'Telefoonnummer'}
                    value={phone}
                    onChange={setPhone}
                />
                {extended && renderExtended()}
                <TextArea required={true} name={'bericht'} placeholder={'Uw bericht *'} value={msg} onChange={setMsg} />
                <div className={'float-right'}>
                    <Button text={extended ? 'Maak afspraak' : 'Contact opnemen'} action={sendMessage} />
                </div>
            </Col>
        )
    }

    return (
        <div data-testid={testID} className={`ContactForm`}>
            {renderContent()}
        </div>
    )
}

export default ContactForm
