// import { useState, useEffect, useContext } from 'react'
import { LogoInterface } from './Logo.interface'
import LogoImg from '../../../resources/images/thiago-logo-white.svg'
import { Link } from 'react-router-dom'

const Logo = ({ testID }: LogoInterface) => {
    return (
        <Link to={'/'} data-testid={testID} className={`Logo`}>
            <img src={LogoImg} alt='guilhermo-logo' />
        </Link>
    )
}

export default Logo
