import { OptionItemInterface } from './OptionItem.interface'
import { Col } from 'react-bootstrap'
import { Button, Heading } from '../../atoms'
import { Link } from 'react-router-dom'

const OptionItem = ({ testID, item }: OptionItemInterface) => {
    return (
        <Col data-testid={testID} className={`OptionItem`} key={`tile-${item?.id}`} md={3} sm={6} xs={6}>
            <a href={item?.link}>
                <img src={item?.img} alt={item?.title} />
            </a>

            <Heading type={'h4'} text={item?.title} color={'#827A65'} />
        </Col>
    )
}

export default OptionItem
