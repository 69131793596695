// import { useState, useEffect, useContext } from 'react'
import { OptionsInterface } from './Options.interface'
import { config } from '../../../config'
import { Col, Container, Row } from 'react-bootstrap'
import { OptionItem } from '../../atoms'

const Options = ({ testID }: OptionsInterface) => {
    const tiles = config.tiles

    return (
        <Container>
            <Row data-testid={testID} className={`Options`}>
                {tiles?.map((tile, index: number) => {
                    return <OptionItem key={`option-${index}`} item={tile} />
                })}
            </Row>
        </Container>
    )
}

export default Options
