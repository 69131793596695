// import { useState, useEffect, useContext } from 'react'
import { PriceTagInterface } from './PriceTag.interface'
import { Link } from 'react-router-dom'

const PriceTag = ({ testID }: PriceTagInterface) => {
    return (
        <Link to={'/contact'} data-testid={testID} className={`PriceTag`}>
            <div className={'text-big'}>
                <span className={'text-medium'}>uitsluitend op afspraak.</span>
                maandag t/m zaterdag
                <span className={'text-medium'}>ook op locatie</span>
            </div>
        </Link>
    )
}

export default PriceTag
