import { ButtonInterface } from './Button.interface'

const Button = ({ testID, type, text, action, active = true }: ButtonInterface) => {
    const handler = () => {
        if (typeof action !== 'string') action()
        // if (typeof action === 'string') alert('Navigate to: ' + action)
    }

    return (
        <div
            data-testid={testID}
            data-object-type={type ?? ''}
            data-active={active}
            onClick={() => handler()}
            className={`Button`}
        >
            {text}
        </div>
    )
}

export default Button
