// import { useState, useEffect, useContext } from 'react'
import { NavigationInterface } from './Navigation.interface'
import { Outlet, Link, useLocation } from 'react-router-dom'
import { AboutUs, Debug, Footer, Hero, Menu, PriceBar } from '../../organisms'
import { Container } from 'react-bootstrap'
import { MobileBar, Options, PriceTag } from '../../molecules'
import { config } from '../../../config'
import { MakeAppointment } from '../../atoms'

const Navigation = ({ testID, data }: NavigationInterface) => {
    const route = useLocation().pathname
    const current = data.find((item: any) => item.slug === route)
    const sub = data.filter((item: any) => item.type === 'informatie')

    const { slogan, heroText } = config

    return (
        <div data-testid={testID} className={`Navigation`}>
            <Container fluid style={{ padding: 0, margin: 0 }}>
                <Hero
                    page={route === '/' && current}
                    heroSlogan={slogan || ''}
                    heroText={heroText || ''}
                    type={route === '/' ? 'home' : 'other'}
                    img={current?.img}
                />
                <MobileBar />

                <Outlet />
                {/*{route === '/' && <PriceBar />}*/}
                {route === '/' && <Options />}
                {route === '/' && <AboutUs />}
                <Footer nav={sub} />
                <Menu data={data} active={current} />
                <PriceTag />
                <MakeAppointment />
            </Container>
        </div>
    )
}

export default Navigation
