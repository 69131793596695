// import { useState, useEffect, useContext } from 'react'
import { InfoBlockInterface } from './InfoBlock.interface'
import { Col, Row } from 'react-bootstrap'
import React from 'react'
import { Heading, Paragraph } from '../../atoms'

const InfoBlock = ({ testID, type = 'right', content, shadowed = false }: InfoBlockInterface) => {
    const { img, title, paragraph } = content

    const renderLeft = () => {
        if (type === 'left') {
            return (
                <div className='InfoBlock__left'>
                    {/*{ content }*/}
                    <Row className={'left-row'}>
                        <Col md={8} className={'left-content'}>
                            <div className={'description'}>
                                <Heading type={'h2'} text={title} color={'#000'} />
                                <Paragraph type={'markdown'} content={paragraph} format={true} />
                            </div>
                        </Col>
                        <Col md={4} className={'left-image'}>
                            {img && <img src={img} alt={title} className={shadowed ? 'shadowed' : ''} />}
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    const renderRight = () => {
        if (type === 'right') {
            return (
                <div className='InfoBlock__right'>
                    <Row className={'right-row'}>
                        <Col md={4} className={'right-image'}>
                            {img && <img src={img} alt={title} />}
                        </Col>
                        <Col md={8} className={'right-content'}>
                            <div className={'description'}>
                                <Heading type={'h2'} text={title} color={'#000'} />
                                <Paragraph type={'markdown'} content={paragraph} />
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }

    return (
        <div data-testid={testID} data-object-type={type ?? ''} className={`InfoBlock`}>
            {renderLeft()}
            {renderRight()}
        </div>
    )
}

export default InfoBlock
