// import { useState, useEffect, useContext } from 'react'
import { SpinnerInterface } from './Spinner.interface'
import ScaleLoader from 'react-spinners/ScaleLoader'

const Spinner = ({ testID }: SpinnerInterface) => {
    return (
        <div data-testid={testID} className={`Spinner`}>
            <ScaleLoader color={'#827A65'} height={55} width={10} aria-label='Loading Spinner' data-testid='loader' />
        </div>
    )
}

export default Spinner
