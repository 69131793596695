import { AboutUsInterface } from './AboutUs.interface'
import { useContext } from 'react'
import { AppContext } from '../../../context'
import { Button, Heading, Paragraph } from '../../atoms'
import { Col, Container, Row } from 'react-bootstrap'
import { Options } from '../../molecules'
import { Link } from 'react-router-dom'

const AboutUs = ({ testID }: AboutUsInterface) => {
    const ctx = useContext(AppContext)
    const data = ctx.find((item: any) => item.type === 'homepageAbout')
    const { img, title, pageContent } = data

    return (
        <Container fluid data-testid={testID} className={`AboutUs`}>
            <Row>
                <Col md={2} />
                <Col md={8}>
                    <Row>
                        <Col md={5}>
                            <img src={img} alt='about-us' />
                        </Col>
                        <Col md={6}>
                            <Heading type={'h2'} text={title} color={'#FFF'} />
                            {pageContent.map((item: any, index: number) => {
                                return <Paragraph key={index} type={'markdown'} content={item.paragraph} />
                            })}
                        </Col>
                    </Row>
                </Col>
                <Col md={2} />
            </Row>
            {/*<Row>*/}
            {/*    <Link to={'/over-ons'} className={'button-bar'}>*/}
            {/*        <Button text={'lees meer'} action={'/over-ons'} type={'inverse'} />*/}
            {/*    </Link>*/}
            {/*</Row>*/}
        </Container>
    )
}

export default AboutUs
