import { useState, useEffect } from 'react'
import { ProductPageInterface } from './ProductPage.interface'
import { useDatabase } from '../../../hooks'
import { Debug, ProductList } from '../../organisms'
import { InfoBlock } from '../../molecules'
import { Button } from '../../atoms'

const ProductPage = ({ testID, type, page }: ProductPageInterface) => {
    const [filter, setFilter] = useState<string>('toon alles')
    const [data, isLoaded] = useDatabase(('guilhermo_' + type) as string)
    const [filteredData, setFilteredData] = useState<any[]>(data)
    const [filters, setFilters] = useState<any[]>([])

    const padArray = (arr: any, targetLength: number) => {
        return arr.concat(Array(targetLength - arr.length).fill(undefined))
    }

    const nextMultipleOfThree = (num: number) => {
        return num + ((3 - (num % 3)) % 3)
    }

    useEffect(() => {
        if (!isLoaded) return
        const unique = [...new Set(data.map((item) => item.category))]
        const f = ['toon alles']
        f.push(...unique.filter((n) => n))
        setFilters(f)
        const filtered = padArray(data, nextMultipleOfThree(data.length))
        setFilteredData(filtered)
    }, [data, isLoaded])

    const filterData = (filter: string) => {
        setFilter(filter)
        if (filter === 'toon alles') {
            const filtered = padArray(data, nextMultipleOfThree(data.length))
            setFilteredData(filtered)
        } else {
            const f = data.filter((item) => item.category === filter)
            const filtered = padArray(f, nextMultipleOfThree(f.length))
            setFilteredData(filtered)
        }
    }

    const renderHeader = () => {
        return (
            <div className='ProductPage__header'>
                <InfoBlock type='left' content={page.pageContent[0]} shadowed={type === 'materials'} />
            </div>
        )
    }

    const renderFilters = () => {
        if (filters.length > 1) {
            return (
                <div className='ProductPage__filters'>
                    {filters
                        .sort((a, b) => parseInt(b.order) - parseInt(a.order))
                        .map((item, index) => {
                            return (
                                <Button
                                    text={item}
                                    type={'pill'}
                                    active={filter === item}
                                    key={index}
                                    action={() => filterData(item)}
                                />
                            )
                        })}
                </div>
            )
        }
    }

    const renderProductList = () => {
        if (isLoaded) {
            return <ProductList data={filteredData} type={type} />
        }
    }

    return (
        <div data-testid={testID} data-object-type={type ?? ''} className={`ProductPage`}>
            {renderHeader()}
            {renderFilters()}
            {renderProductList()}
        </div>
    )
}

export default ProductPage
