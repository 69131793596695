import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Navigation } from './components/templates'
import { HomePage, InformationPage, ProductPage } from './components/pages'
import { AppContext } from './context'
import { useDatabase } from './hooks'
import ScrollToTop from './components/atoms/ScrollToTop'

const App = () => {
    const [data, isLoaded] = useDatabase('thiago-new_pages')

    const renderContent = () => {
        if (isLoaded) {
            return (
                <Routes>
                    <Route path='/' element={<Navigation data={data} />}>
                        <Route index element={<HomePage data={data} />} />
                        <Route
                            path='/algemene-voorwaarden'
                            element={<InformationPage type='algemene-voorwaarden' data={data} />}
                        />
                        <Route path='/privacy' element={<InformationPage type='privacy' data={data} />} />
                        <Route
                            path='/products'
                            element={
                                <ProductPage
                                    type='models'
                                    page={data.find((item) => item.slug === '/products/modellen')}
                                />
                            }
                        />
                        <Route
                            path='/products/modellen'
                            element={
                                <ProductPage
                                    type='models'
                                    page={data.find((item) => item.slug === '/products/modellen')}
                                />
                            }
                        />
                        <Route
                            path='/products/sneakers'
                            element={
                                <ProductPage
                                    type='sneakers'
                                    page={data.find((item) => item.slug === '/products/sneakers')}
                                />
                            }
                        />
                        <Route
                            path='/products/bijpassende-riem'
                            element={
                                <ProductPage
                                    type='belts'
                                    page={data.find((item) => item.slug === '/products/bijpassende-riem')}
                                />
                            }
                        />
                        <Route
                            path='/products/gespen'
                            element={
                                <ProductPage
                                    type='belts'
                                    page={data.find((item) => item.slug === '/products/bijpassende-riem')}
                                />
                            }
                        />
                        <Route
                            path='/products/customs'
                            element={
                                <ProductPage
                                    type='customs'
                                    page={data.find((item) => item.slug === '/products/customs')}
                                />
                            }
                        />
                        <Route
                            path='/products/materialen-en-kleuren'
                            element={
                                <ProductPage
                                    type='materials'
                                    page={data.find((item) => item.slug === '/products/materialen-en-kleuren')}
                                />
                            }
                        />
                        {/*<Route path='/over-ons' element={<InformationPage type='over-ons' data={data} />} />*/}
                        <Route path='/contact' element={<InformationPage type='contact' data={data} />} />
                        <Route path='/afspraak' element={<InformationPage type='afspraak' data={data} />} />
                    </Route>
                </Routes>
            )
        }
    }

    return (
        <AppContext.Provider value={data}>
            <BrowserRouter>
                <ScrollToTop />
                {renderContent()}
            </BrowserRouter>
        </AppContext.Provider>
    )
}

export default App
