// import { useState, useEffect, useContext } from 'react'
import { ProductBlockInterface } from './ProductBlock.interface'
import { Link } from 'react-router-dom'
import { Heading } from '../../atoms'
import { Col, Row } from 'react-bootstrap'

const ProductBlock = ({ testID, img, title, description, link }: ProductBlockInterface) => {
    return (
        <Col md={4} sm={4} xs={4} className={`ProductBlock`}>
            <Link to={link} data-testid={testID}>
                <Row className={'content'}>
                    <Col md={5} sm={3} xs={3}>
                        <img src={img} alt={title} />
                    </Col>

                    <Col md={7} sm={4} xs={4}>
                        <div className={'stuff'}>
                            <Heading type={'h5'} text={title} color={'#000'} />
                            <p className={'text'}>{description}</p>
                        </div>
                    </Col>
                </Row>

                <div className={'selector'}>
                    <i className='bi bi-arrow-right'></i>
                </div>
            </Link>
        </Col>
    )
}

export default ProductBlock
