import { FooterInterface } from './Footer.interface'
import { Container, Row, Col } from 'react-bootstrap'
import { Button, Heading } from '../../atoms'
import GuilhermoG from '../../../resources/images/thiago-logo-white.svg'
import { config } from '../../../config'
import { Link } from 'react-router-dom'

const Footer = ({ testID, nav }: FooterInterface) => {
    const { street, postalCode, city, email, phone } = config.address

    return (
        <div data-testid={testID} className={`Footer`}>
            <Container fluid>
                <Row>
                    <Col md={1} />
                    <Col md={10}>
                        <Row>
                            <Col md={3} sm={12} xs={12} className={'logo data-col'}>
                                <img src={GuilhermoG} alt={'Guilhermo'} />
                            </Col>
                            <Col md={3} sm={6} xs={12} className={'data-col-text'}>
                                <Heading type={'h3'} text={'Adres'} color={'#FFF'} />
                                <div className={'text'}>
                                    {street}
                                    <br />
                                    {postalCode} {city}
                                </div>
                            </Col>
                            <Col md={3} sm={6} xs={12} className={'data-col-text'}>
                                <Heading type={'h3'} text={'Contact'} color={'#FFF'} />
                                <div className={'text'}>
                                    Email: <a href={email}>{email}</a>
                                    <br />
                                    Telefoon: <a href={`tel:${phone}`}>{phone}</a>
                                </div>
                            </Col>
                            <Col md={3} sm={6} xs={12} className={'links data-col-text'}>
                                <Heading type={'h3'} text={'Informatie'} color={'#FFF'} />
                                <ul>
                                    {nav.map((item: any, index: number) => {
                                        return (
                                            <li key={index}>
                                                <Link key={index} to={item.slug}>
                                                    {item.title}
                                                </Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Col>
                            <Col md={2} />
                        </Row>
                    </Col>

                    <Col md={1} />
                </Row>
                <Row>
                    <Col className={'full-width-center'}>
                        <a href={`tel:${phone}`}>
                            <Button text={'bel ons direct'} action={phone} />
                        </a>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer
